.app {
  /* Some app-specific CSS variables that aren't part of the shared packages */
  --dex-ui-app-color: var(--core-surface-5-color);
  --dex-nav-sticky-position: calc(5 * var(--dex-spacing-1x));

  /* Center the page for very large displays */
  display: flex;
  justify-content: center;

  background-color: var(--dex-ui-app-color);
}
