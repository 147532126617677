/*
  These are a direct copy from the following market mixins. Unfortunately, CSS modules
  doesn't allow you to import and extend at the same time
  @import '@market/web-components/dist/market/styles/mixins/typography.css';
*/

%base-type {
  font-family: var(--core-type-font-family);
  font-weight: var(--core-type-regular-weight);
  color: var(--core-text-10-color);
  font-synthesis: none;
  font-smoothing: always; /* stylelint-disable-line */
  -moz-osz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

%paragraph-30 {
  font-family: var(--core-type-paragraph-30-font-family);
  font-size: var(--core-type-paragraph-30-size);
  line-height: var(--core-type-paragraph-30-leading);
  letter-spacing: var(--core-type-paragraph-30-tracking);
  font-weight: var(--core-type-paragraph-30-weight);
  text-transform: var(--core-type-paragraph-30-case);

  /* Our tooltips use a market tooltip, which only allows styling through
  CSS variables. So we are allowing the text components to style the tooltips inline, as if it was normal text */
  --dex-tooltip-text-size: var(--core-type-paragraph-30-size);
  --dex-tooltip-text-leading: var(--core-type-paragraph-30-leading);
  --dex-tooltip-text-tracking: var(--core-type-paragraph-30-tracking);
  --dex-tooltip-text-weight: var(--core-type-paragraph-30-weight);
  --dex-tooltip-text-case: var(--core-type-paragraph-30-case);
}

%paragraph-20 {
  font-family: var(--core-type-paragraph-20-font-family);
  font-size: var(--core-type-paragraph-20-size);
  line-height: var(--core-type-paragraph-20-leading);
  letter-spacing: var(--core-type-paragraph-20-tracking);
  font-weight: var(--core-type-paragraph-20-weight);
  text-transform: var(--core-type-paragraph-20-case);

  --dex-tooltip-text-size: var(--core-type-paragraph-20-size);
  --dex-tooltip-text-leading: var(--core-type-paragraph-20-leading);
  --dex-tooltip-text-tracking: var(--core-type-paragraph-20-tracking);
  --dex-tooltip-text-weight: var(--core-type-paragraph-20-weight);
  --dex-tooltip-text-case: var(--core-type-paragraph-20-case);
}

%paragraph-10 {
  font-family: var(--core-type-paragraph-10-font-family);
  font-size: var(--core-type-paragraph-10-size);
  line-height: var(--core-type-paragraph-10-leading);
  letter-spacing: var(--core-type-paragraph-10-tracking);
  font-weight: var(--core-type-paragraph-10-weight);
  text-transform: var(--core-type-paragraph-10-case);

  --dex-tooltip-text-size: var(--core-type-paragraph-10-size);
  --dex-tooltip-text-leading: var(--core-type-paragraph-10-leading);
  --dex-tooltip-text-tracking: var(--core-type-paragraph-10-tracking);
  --dex-tooltip-text-weight: var(--core-type-paragraph-10-weight);
  --dex-tooltip-text-case: var(--core-type-paragraph-10-case);
}

%medium-10 {
  font-family: var(--core-type-medium-10-font-family);
  font-size: var(--core-type-medium-10-size);
  line-height: var(--core-type-medium-10-leading);
  letter-spacing: var(--core-type-medium-10-tracking);
  font-weight: var(--core-type-medium-10-weight);
  text-transform: var(--core-type-medium-10-case);

  --dex-tooltip-text-size: var(--core-type-medium-10-size);
  --dex-tooltip-text-leading: var(--core-type-medium-10-leading);
  --dex-tooltip-text-tracking: var(--core-type-medium-10-tracking);
  --dex-tooltip-text-weight: var(--core-type-medium-10-weight);
  --dex-tooltip-text-case: var(--core-type-medium-10-case);
}

%medium-20 {
  font-family: var(--core-type-medium-20-font-family);
  font-size: var(--core-type-medium-20-size);
  line-height: var(--core-type-medium-20-leading);
  letter-spacing: var(--core-type-medium-20-tracking);
  font-weight: var(--core-type-medium-20-weight);
  text-transform: var(--core-type-medium-20-case);

  --dex-tooltip-text-size: var(--core-type-medium-20-size);
  --dex-tooltip-text-leading: var(--core-type-medium-20-leading);
  --dex-tooltip-text-tracking: var(--core-type-medium-20-tracking);
  --dex-tooltip-text-weight: var(--core-type-medium-20-weight);
  --dex-tooltip-text-case: var(--core-type-medium-20-case);
}

%medium-30 {
  font-family: var(--core-type-medium-30-font-family);
  font-size: var(--core-type-medium-30-size);
  line-height: var(--core-type-medium-30-leading);
  letter-spacing: var(--core-type-medium-30-tracking);
  font-weight: var(--core-type-medium-30-weight);
  text-transform: var(--core-type-medium-30-case);

  --dex-tooltip-text-size: var(--core-type-medium-30-size);
  --dex-tooltip-text-leading: var(--core-type-medium-30-leading);
  --dex-tooltip-text-tracking: var(--core-type-medium-30-tracking);
  --dex-tooltip-text-weight: var(--core-type-medium-30-weight);
  --dex-tooltip-text-case: var(--core-type-medium-30-case);
}

%semibold-10 {
  font-family: var(--core-type-semibold-10-font-family);
  font-size: var(--core-type-semibold-10-size);
  line-height: var(--core-type-semibold-10-leading);
  letter-spacing: var(--core-type-semibold-10-tracking);
  font-weight: var(--core-type-semibold-10-weight);
  text-transform: var(--core-type-semibold-10-case);

  --dex-tooltip-text-size: var(--core-type-semibold-10-size);
  --dex-tooltip-text-leading: var(--core-type-semibold-10-leading);
  --dex-tooltip-text-tracking: var(--core-type-semibold-10-tracking);
  --dex-tooltip-text-weight: var(--core-type-semibold-10-weight);
  --dex-tooltip-text-case: var(--core-type-semibold-10-case);
}

%semibold-20 {
  font-family: var(--core-type-semibold-20-font-family);
  font-size: var(--core-type-semibold-20-size);
  line-height: var(--core-type-semibold-20-leading);
  letter-spacing: var(--core-type-semibold-20-tracking);
  font-weight: var(--core-type-semibold-20-weight);
  text-transform: var(--core-type-semibold-20-case);
  --dex-tooltip-text-weight: var(--core-type-semibold-20-weight);

  --dex-tooltip-text-size: var(--core-type-semibold-20-size);
  --dex-tooltip-text-leading: var(--core-type-semibold-20-leading);
  --dex-tooltip-text-tracking: var(--core-type-semibold-20-tracking);
  --dex-tooltip-text-weight: var(--core-type-semibold-20-weight);
  --dex-tooltip-text-case: var(--core-type-semibold-20-case);
}

%semibold-30 {
  font-family: var(--core-type-semibold-30-font-family);
  font-size: var(--core-type-semibold-30-size);
  line-height: var(--core-type-semibold-30-leading);
  letter-spacing: var(--core-type-semibold-30-tracking);
  font-weight: var(--core-type-semibold-30-weight);
  text-transform: var(--core-type-semibold-30-case);
  --dex-tooltip-text-weight: var(--core-type-semibold-30-weight);

  --dex-tooltip-text-size: var(--core-type-semibold-30-size);
  --dex-tooltip-text-leading: var(--core-type-semibold-30-leading);
  --dex-tooltip-text-tracking: var(--core-type-semibold-30-tracking);
  --dex-tooltip-text-weight: var(--core-type-semibold-30-weight);
  --dex-tooltip-text-case: var(--core-type-semibold-30-case);
}

.base {
  @extend %base-type;
}

.paragraph-30 {
  @extend %paragraph-30;
}

.paragraph-20 {
  @extend %paragraph-20;
}

.paragraph-10 {
  @extend %paragraph-10;
}

.medium-30 {
  @extend %medium-30;
}

.medium-20 {
  @extend %medium-20;
}

.medium-10 {
  @extend %medium-10;
}

.semi-bold-30 {
  @extend %semibold-30;
}

.semi-bold-20 {
  @extend %semibold-20;
}

.semi-bold-10 {
  @extend %semibold-10;
}

.code-field {
  font-family: var(--dex-code-font-family);
  color: var(--core-text-10-color);
}

.color-10 {
  color: var(--core-text-10-color);
}

.color-20 {
  color: var(--core-text-20-color);
}

.color-30 {
  color: var(--core-text-30-color);
}

.color-inverse {
  color: var(--core-text-inverse-color);
}

.color-black {
  color: var(--core-text-black-color);
}

.color-white {
  color: var(--core-text-white-color);
}

.color-currentColor {
  color: currentColor;
}
