.tooltip {
  --tooltip-min-width: 0px;
  --tooltip-min-height: var(--core-type-paragraph-30-leading);
}

.hidden {
  display: none;
  visibility: hidden;
  width: 0px;
  height: 0px;
}

.dropdown {
  /* By default, the market-dropdown is display: inline-block, but that means the text won't wrap */
  display: inline;
}

.popover-content {
  max-width: var(--tooltip-popover-max-width, 280px);
  padding: var(--tooltip-popover-padding-vertical-size, 8px)
    var(--tooltip-popover-padding-horizontal-size, 12px);
  border-radius: var(--tooltip-popover-border-radius, 6px);
  border-width: 0px;
  background-color: var(
    --tooltip-popover-background-color,
    var(--core-surface-inverse-color)
  );
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px, rgba(0, 0, 0, 0.1) 0px 4px 32px;
}

.inverse-color {
  color: var(--tooltip-popover-text-color, var(--core-text-inverse-color));
}

.link-preview-popover-layout {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-color: var(--dex-preview-tooltip-divider-color);
  --horizontal-margin: calc(1.5 * var(--dex-spacing-1x));

  margin-left: calc(-1 * var(--horizontal-margin));
  margin-right: calc(-1 * var(--horizontal-margin));
  padding-left: var(--horizontal-margin);
  padding-right: var(--horizontal-margin);

  /* Need to pull in the popover bottom margin by 2px if the preview layout is there */
  margin-bottom: calc(-0.25 * var(--dex-spacing-1x));
}

.limit-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
