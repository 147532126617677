:root {
  /* App Grid */
  /* Small viewports */
  --dex-app-grid-template-columns-small: auto;
  --dex-app-grid-margin-left-small: 24px;
  --dex-app-grid-margin-right-small: 24px;
  --dex-app-grid-column-gap-small: 0px;
  --dex-app-grid-row-gap-small: 0px;

  --dex-app-grid-item-full-size-column-span-small: 1;
  --dex-app-grid-item-grid-item-medium-size-column-span-small: 1;
  --dex-app-grid-item-grid-item-small-size-column-span-small: 1;

  /* Medium viewports */
  --dex-app-grid-template-columns-medium: 180px auto;
  --dex-app-grid-margin-left-medium: 56px;
  --dex-app-grid-margin-right-medium: 0px;
  --dex-app-grid-column-gap-medium: 0px;
  --dex-app-grid-row-gap-medium: 0px;

  --dex-app-grid-item-full-size-column-span-medium: 2;
  --dex-app-grid-item-medium-size-column-span-medium: 1;
  --dex-app-grid-item-small-size-column-span-medium: 1;

  /* Large viewports */
  --dex-app-grid-template-columns-large: 180px auto;
  --dex-app-grid-margin-left-large: 56px;
  --dex-app-grid-margin-right-large: 0px;
  --dex-app-grid-column-gap-large: 56px;
  --dex-app-grid-row-gap-large: 0px;

  --dex-app-grid-item-full-size-column-span-large: 2;
  --dex-app-grid-item-medium-size-column-span-large: 1;
  --dex-app-grid-item-small-size-column-span-large: 1;

  /* Content Grid */
  /* Small viewports */
  --dex-content-grid-template-columns-small: auto;
  --dex-content-grid-margin-left-small: 0;
  --dex-content-grid-margin-right-small: 0;
  --dex-content-grid-column-gap-small: 56px;
  --dex-content-grid-row-gap-small: 0px;

  --dex-content-grid-item-full-size-column-span-small: 1;
  --dex-content-grid-item-medium-size-column-span-small: 1;
  --dex-content-grid-item-small-size-column-span-small: 1;

  /* Medium viewports */
  --dex-content-grid-template-columns-medium: auto;
  --dex-content-grid-margin-left-medium: 56px;
  --dex-content-grid-margin-right-medium: 0px;
  --dex-content-grid-column-gap-medium: 0px;
  --dex-content-grid-row-gap-medium: 56px;

  --dex-content-grid-item-full-size-column-span-medium: 1;
  --dex-content-grid-item-medium-size-column-span-medium: 1;
  --dex-content-grid-item-small-size-column-span-medium: 1;

  /* Large viewports */
  /**
   * Manually calculated percentages for this track.
   * We expect it to max out at 720px and 588px respectively, and shrink from there.
   * However, with CSS grid, the only way to do that is to use %. If we just use "auto",
   * then the columns will shrink randomly, and shift across pages.
   * Therefore, to get the %, it's 720px (left) + 588px (right) + 56px (gap) = 1364px as the total.
   * We could calcualate it all with CSS variables, but when it was done it was messier and less obvious
   * than being explicit
  */
  --dex-content-grid-template-columns-large: min(52.7859237536657%, 720px)
    min(43.108504398827%, 588px);
  --dex-content-grid-margin-left-large: 0px;
  --dex-content-grid-margin-right-large: 0px;
  --dex-content-grid-column-gap-large: 56px;
  --dex-content-grid-row-gap-large: 0px;

  --dex-content-grid-item-full-size-column-span-large: 2;
  --dex-content-grid-item-medium-size-column-span-large: 1;
  --dex-content-grid-item-small-size-column-span-large: 1;

  /* Docs App Grid */
  /* Small viewports */
  --dex-docs-app-grid-template-columns-small: auto;
  --dex-docs-app-grid-margin-left-small: 0px;
  --dex-docs-app-grid-margin-right-small: 0px;
  --dex-docs-app-grid-column-gap-small: 0px;
  --dex-docs-app-grid-row-gap-small: 0px;

  --dex-docs-app-grid-item-full-size-column-span-small: 1;
  --dex-docs-app-grid-item-grid-item-medium-size-column-span-small: 1;
  --dex-docs-app-grid-item-grid-item-small-size-column-span-small: 1;

  /* Medium viewports */
  --dex-docs-app-grid-template-columns-medium: 320px auto;
  --dex-docs-app-grid-template-columns-medium-collapsed: auto;
  --dex-docs-app-grid-margin-left-medium: 0px;
  --dex-docs-app-grid-margin-right-medium: 0px;
  --dex-docs-app-grid-column-gap-medium: 0px;
  --dex-docs-app-grid-row-gap-medium: 0px;

  --dex-docs-app-grid-item-full-size-column-span-medium: 2;
  --dex-docs-app-grid-item-medium-size-column-span-medium: 1;
  --dex-docs-app-grid-item-small-size-column-span-medium: 1;

  /* Large viewports */
  --dex-docs-app-grid-template-columns-large: 320px auto;
  --dex-docs-app-grid-template-columns-large-collapsed: auto;
  --dex-docs-app-grid-margin-left-large: 0px;
  --dex-docs-app-grid-margin-right-large: 0px;
  --dex-docs-app-grid-column-gap-large: 0px;
  --dex-docs-app-grid-row-gap-large: 0px;

  --dex-docs-app-grid-item-full-size-column-span-large: 2;
  --dex-docs-app-grid-item-medium-size-column-span-large: 1;
  --dex-docs-app-grid-item-small-size-column-span-large: 1;

  /* Docs Content Grid */
  /* Small viewports */
  --dex-docs-content-grid-template-columns-small: minmax(0, max-content);
  --dex-docs-content-grid-margin-left-small: 24px;
  --dex-docs-content-grid-margin-right-small: 24px;
  --dex-docs-content-grid-column-gap-small: 0px;
  --dex-docs-content-grid-row-gap-small: 0px;

  --dex-docs-content-grid-item-full-size-column-span-small: 1;
  --dex-docs-content-grid-item-medium-size-column-span-small: 1;
  --dex-docs-content-grid-item-small-size-column-span-small: 1;

  /* Medium viewports */
  --dex-docs-content-grid-template-columns-medium: minmax(0, max-content);
  --dex-docs-content-grid-margin-left-medium: 72px;
  --dex-docs-content-grid-margin-right-medium: 72px;
  --dex-docs-content-grid-column-gap-medium: 0px;
  --dex-docs-content-grid-row-gap-medium: 0px;

  --dex-docs-content-grid-item-full-size-column-span-medium: 2;
  --dex-docs-content-grid-item-medium-size-column-span-medium: 2;
  --dex-docs-content-grid-item-small-size-column-span-medium: 1;

  /* Large viewports */
  --dex-docs-content-grid-template-columns-large: minmax(0, max-content) 180px;
  --dex-docs-content-grid-margin-left-large: 0px;
  --dex-docs-content-grid-margin-right-large: 0px;
  --dex-docs-content-grid-column-gap-large: 48px;
  --dex-docs-content-grid-row-gap-large: 0px;

  --dex-docs-content-grid-item-full-size-column-span-large: 2;
  --dex-docs-content-grid-item-medium-size-column-span-large: 1;
  --dex-docs-content-grid-item-small-size-column-span-large: 1;

  --dex-docs-layout-transition-timing: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  /* Devs-console App Grid */
  /* Small viewports */
  --dex-devs-console-app-grid-template-columns-small: auto;
  --dex-devs-console-app-grid-margin-left-small: 0px;
  --dex-devs-console-app-grid-margin-right-small: 0px;
  --dex-devs-console-app-grid-column-gap-small: 0px;
  --dex-devs-console-app-grid-row-gap-small: 0px;

  --dex-devs-console-app-grid-item-full-size-column-span-small: 1;
  --dex-devs-console-app-grid-item-grid-item-medium-size-column-span-small: 1;
  --dex-devs-console-app-grid-item-grid-item-small-size-column-span-small: 1;

  /* Medium viewports */
  --dex-devs-console-app-grid-template-columns-medium: 264px auto;
  --dex-devs-console-app-grid-template-columns-medium-collapsed: auto;
  --dex-devs-console-app-grid-margin-left-medium: 0px;
  --dex-devs-console-app-grid-margin-right-medium: 0px;
  --dex-devs-console-app-grid-column-gap-medium: 0px;
  --dex-devs-console-app-grid-row-gap-medium: 0px;

  --dex-devs-console-app-grid-item-full-size-column-span-medium: 2;
  --dex-devs-console-app-grid-item-medium-size-column-span-medium: 1;
  --dex-devs-console-app-grid-item-small-size-column-span-medium: 1;

  /* Large viewports */
  --dex-devs-console-app-grid-template-columns-large: 264px auto;
  --dex-devs-console-app-grid-template-columns-large-collapsed: auto;
  --dex-devs-console-app-grid-margin-left-large: 0px;
  --dex-devs-console-app-grid-margin-right-large: 0px;
  --dex-devs-console-app-grid-column-gap-large: 0px;
  --dex-devs-console-app-grid-row-gap-large: 0px;

  --dex-devs-console-app-grid-item-full-size-column-span-large: 2;
  --dex-devs-console-app-grid-item-medium-size-column-span-large: 1;
  --dex-devs-console-app-grid-item-small-size-column-span-large: 1;

  /* Left FTOC Grid */
  /* Small viewports */
  --dex-left-ftoc-grid-template-columns-small: auto;
  --dex-left-ftoc-grid-margin-left-small: 72px;
  --dex-left-ftoc-grid-margin-right-small: 72px;
  --dex-left-ftoc-grid-column-gap-small: 0;
  --dex-left-ftoc-grid-row-gap-small: 0;

  /* Medium viewports */
  --dex-left-ftoc-grid-template-columns-medium: 180px 600px;
  --dex-left-ftoc-grid-margin-horizonal: 32px;
  --dex-left-ftoc-grid-margin-left-medium: calc(
    (
        100vw - (2 * var(--dex-left-ftoc-grid-margin-horizonal)) -
          calc(180px + 600px + var(--dex-left-ftoc-grid-column-gap-medium))
      ) / 2
  );
  --dex-left-ftoc-grid-margin-right-medium: var(
    --dex-left-ftoc-grid-margin-left-medium
  );
  --dex-left-ftoc-grid-column-gap-medium: 48px;
  --dex-left-ftoc-grid-row-gap-medium: 0;
}
