@import '../reset/reset.css';

/* Pull in the design token variables for usage across the system */
@import '../design-tokens/variables.css';

/* Pull in the themes wanted by this application */
@import '../themes/base-theme.css';
@import '../themes/dim-theme.css';

@import '../fonts/fonts.css';

%base-type {
  font-family: var(--core-type-font-family);
  font-weight: var(--core-type-regular-weight);
  color: var(--core-text-10-color);
  font-synthesis: none;
  font-smoothing: always; /* stylelint-disable-line */
  -moz-osz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  @extend %base-type;

  font-size: 100%;
  font-display: fallback;
  font-family: var(--square-sans-text);

  /* Market is shoving in an overflow:hidden, which hides the page.
     They also add it to the body, which has the effect of stopping scrolling
     for modals already. So pulling out an !important, even though it's bad practice. */
  overflow: initial !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  background-color: var(--core-surface-5-color);
}

html,
body,
#__next {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

body.noscroll {
  overflow: hidden;
}

/* Need to override default zindex */
market-context-manager[active] {
  /* GraphiQL has a z-index of 6. Need to be higher to avoid z-fighting with scroll bars */
  z-index: 7;
}
