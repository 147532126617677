:root {
  /*
    WARNING!!! This file is DEPECATED please do not add to it.
    We are now using the proper files under /themes to create defintions of variables.
    WI: https://jira.sqprod.co/browse/DEX-10424

    Market figma found here
    https://www.figma.com/file/BKizTk0NavqV0IZS58rPXk/Market-1.0?node-id=23153%3A33401
  */

  /* --------------------------------------------------------Light UI-------------------------------------------------------- */

  /* Fill 40*/
  --light-ui-gray-40: #0000000d; /*DEX #FBFBFC;*/

  --light-ui-gray-200: #e4e7eb;
  --light-ui-gray-300: #d0d3d6;
  --light-ui-gray-400: #909198;
  --light-ui-gray-500: #5f666c;

  /* Fill 20 */
  --light-ui-gray-500: #0000004d; /*DEX #5F666C*/

  --light-ui-gray-700: var(--neutral-gray-700);

  /* Fill 40*/
  --dex-light-ui-gray-40: #0000000D; /*DEX #FBFBFC;*/

  /* Fill 20 */
  --dex-light-ui-gray-500: #0000004D; /*DEX #5F666C*/

  --dex-light-ui-gray-700: var(--neutral-gray-700);

  /* --------------------------------------------------------Dark UI-------------------------------------------------------- */

  /* Surface 20 */
  --dark-ui-black-20: #1c1c1c; /*DEX #273240*/

  /* Emphasis Fill  */
  --dark-ui-blue: #006aff;

  /* Surface 20 */
  --dex-dark-ui-black-20: #1C1C1C; /*DEX #273240*/

  /* Emphasis Fill  */
  --dex-dark-ui-blue: #006AFF;

  /* Emphasis 30 */
  --dark-ui-blue-400: #002559; /*DEX #455162 */

  /*Not sure what to do about this one there is no market close to it*/
  --dark-ui-blue-500: #344151;

  /* Emphasis 30 */
  --dex-dark-ui-blue-400: #002559; /*DEX #455162 */
  --dex-dark-ui-blue-500: #344151;
}
