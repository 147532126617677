.focus-ring {
  outline: transparent;
  position: relative;

  --focus-ring-position: -4px;
}

.no-outline {
  outline: transparent;
}

.no-text-decor,
.no-text-decor:hover {
  text-decoration: none;
}

.focus-ring::before {
  display: block;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease 0s;
  border: var(--core-focus-ring-border);
  border-radius: calc(
    -1 * var(--focus-ring-position) + var(--current-border-radius)
  );

  top: var(--focus-ring-position);
  right: var(--focus-ring-position);
  bottom: var(--focus-ring-position);
  left: var(--focus-ring-position);
}

.focus-ring:focus-visible::before {
  opacity: 0.75;
  /* Moving content here otherwise we are adding a little spacing on all elements,
  causing a small scroll */
  content: '';
}

.focus-ring {
  --current-border-radius: 6px;
}

.focus-ring-6 {
  --current-border-radius: 6px;
}

.focus-ring-8 {
  --current-border-radius: 8px;
}

.focus-ring-12 {
  --current-border-radius: 12px;
}

.focus-ring-omit-spacing {
  --focus-ring-position: 0px;
}
