/* Base mode color variable definitions DEX design created. Talk to design BEFORE adding a color variable to this pallete.  */
:root {
  /* Theme */

  /* Box shadows that aren't in the market CSS yet */
  --core-elevation-text-light-mode-color: 0px 1px 2px rgba(0, 0, 0, 0.2),
    0px 0px 4px rgba(0, 0, 0, 0.2);
  --core-elevation-10-light-mode-color: 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 0px 4px rgba(0, 0, 0, 0.1);
  --core-elevation-20-light-mode-color: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 2px 16px rgba(0, 0, 0, 0.1);
  --core-elevation-30-light-mode-color: 0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 4px 32px rgba(0, 0, 0, 0.1);

  --core-elevation-text-color: var(--core-elevation-text-light-mode-color);
  --core-elevation-10-color: var(--core-elevation-10-light-mode-color);
  --core-elevation-20-color: var(--core-elevation-20-light-mode-color);
  --core-elevation-30-color: var(--core-elevation-30-light-mode-color);

  /* Market colors that don't target the palette */
  --row-hover-state-background-color: var(--core-blue-40-color);

  /* Custom edge case colours */
  --dex-core-dark-blue-400: #455162;

  /* Custom rgba colours */
  /* --core-surface-5-color */
  --dex-background-color-rbga: rgba(255, 255, 255, 1);
  --dex-background-color-rbga-transparent: rgba(255, 255, 255, 0);
  --dex-shim-linear-gradient-colors: rgba(0, 0, 0, 0.07) 0%,
    rgba(242, 242, 242, 0) 100%, rgba(255, 255, 255, 0) 100%,
    rgba(0, 0, 0, 0) 100%;

  --dex-shim-light-linear-gradient-colors: rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0.75%, rgba(255, 255, 255, 0) 2.92%,
    rgba(255, 255, 255, 0.01) 6.37%, rgba(255, 255, 255, 0.02) 10.97%,
    rgba(255, 255, 255, 0.04) 16.59%, rgba(255, 255, 255, 0.06) 23.1%,
    rgba(255, 255, 255, 0.1) 30.37%, rgba(255, 255, 255, 0.15) 38.27%,
    rgba(255, 255, 255, 0.22) 46.66%, rgba(255, 255, 255, 0.3) 55.41%,
    rgba(255, 255, 255, 0.39) 64.39%, rgba(255, 255, 255, 0.51) 73.47%,
    rgba(255, 255, 255, 0.65) 82.52%, rgba(255, 255, 255, 0.81) 91.41%,
    #fff 100%;

  /* core fill 50, but without transparency */
  --core-fill-50-solid-color: rgb(247, 247, 247);

  --dex-code-block-border-color: var(--dex-border-color);

  --dex-preview-tooltip-divider-color: var(--core-fill-30-dim-mode-color);

  /* Capabilities colors  */
  --dex-square-border-color: #9e9e9e;
  --dex-capabilities-payments-fill-color: #12bf94;
  --dex-capabilities-payments-svg-border-color: #ccfff2;
  --dex-capabilities-payments-bg-color: linear-gradient(
    135deg,
    #e5fff9 1.59%,
    var(--dex-capabilities-payments-svg-border-color) 100%
  );
  --dex-capabilities-staff-fill-color: #f25b3d;
  --dex-capabilities-staff-svg-border-color: #ffd4cc;
  --dex-capabilities-staff-bg-color: linear-gradient(
    135deg,
    #ffeae5 1.59%,
    var(--dex-capabilities-staff-svg-border-color) 100%
  );
  --dex-capabilities-customers-fill-color: #d936b0;
  --dex-capabilities-customers-svg-border-color: #ffccf2;
  --dex-capabilities-customers-bg-color: linear-gradient(
    135deg,
    #ffe5f9 1.59%,
    var(--dex-capabilities-customers-svg-border-color) 100%
  );
  --dex-capabilities-commerce-fill-color: #006aff;
  --dex-capabilities-commerce-svg-border-color: #cce1ff;
  --dex-capabilities-commerce-bg-color: linear-gradient(
    135deg,
    #e5f0ff 1.59%,
    var(--dex-capabilities-commerce-svg-border-color) 100%
  );
  --dex-capabilities-merchants-fill-color: #8716d9;
  --dex-capabilities-merchants-svg-border-color: #eaccff;
  --dex-capabilities-merchants-bg-color: linear-gradient(
    135deg,
    #f4e5ff 1.59%,
    var(--dex-capabilities-merchants-svg-border-color) 100%
  );
}

:root,
::before,
::after {
  /* Focus ring isn't in the market CSS, and working around to put them in all scopes*/
  --core-focus-ring-border-light-mode: 2px solid var(--core-blue-fill-color);

  --core-focus-ring-opacity: var(--core-focus-ring-opacity-light-mode);
  --core-focus-ring-border: var(--core-focus-ring-border-light-mode);
  --core-focus-ring-border-radius: 10px;
  --core-focus-ring-space-away: 4px;
}
