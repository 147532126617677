/*
  These are a direct copy from the following market mixins. Unfortunately, CSS modules
  doesn't allow you to import and extend at the same time
  @import '@market/web-components/dist/market/styles/mixins/typography.css';
*/

%base-type {
  font-family: var(--core-type-font-family);
  font-weight: var(--core-type-regular-weight);
  color: var(--core-text-10-color);
  font-synthesis: none;
  font-smoothing: always; /* stylelint-disable-line */
  -moz-osz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

%display-10 {
  font-family: var(--core-type-display-10-font-family);
  font-size: var(--core-type-display-10-size);
  line-height: var(--core-type-display-10-leading);
  letter-spacing: var(--core-type-display-10-tracking);
  font-weight: var(--core-type-display-10-weight);
  text-transform: var(--core-type-display-10-case);
}

%display-20 {
  font-family: var(--core-type-display-20-font-family);
  font-size: var(--core-type-display-20-size);
  line-height: var(--core-type-display-20-leading);
  letter-spacing: var(--core-type-display-20-tracking);
  font-weight: var(--core-type-display-20-weight);
  text-transform: var(--core-type-display-20-case);
}

%heading-30 {
  font-family: var(--core-type-heading-30-font-family);
  font-size: var(--core-type-heading-30-size);
  line-height: var(--core-type-heading-30-leading);
  letter-spacing: var(--core-type-heading-30-tracking);
  font-weight: var(--core-type-heading-30-weight);
  text-transform: var(--core-type-heading-30-case);
}

%heading-20 {
  font-family: var(--core-type-heading-20-font-family);
  font-size: var(--core-type-heading-20-size);
  line-height: var(--core-type-heading-20-leading);
  letter-spacing: var(--core-type-heading-20-tracking);
  font-weight: var(--core-type-heading-20-weight);
  text-transform: var(--core-type-heading-20-case);
}

%heading-10 {
  font-family: var(--core-type-heading-10-font-family);
  font-size: var(--core-type-heading-10-size);
  line-height: var(--core-type-heading-10-leading);
  letter-spacing: var(--core-type-heading-10-tracking);
  font-weight: var(--core-type-heading-10-weight);
  text-transform: var(--core-type-heading-10-case);
}

%heading-5 {
  font-family: var(--core-type-heading-5-font-family);
  font-size: var(--core-type-heading-5-size);
  line-height: var(--core-type-heading-5-leading);
  letter-spacing: var(--core-type-heading-5-tracking);
  font-weight: var(--core-type-heading-5-weight);
  text-transform: var(--core-type-heading-5-case);
}

.base {
  @extend %base-type;
  word-break: break-word;
}

.display-20 {
  @extend %display-20;
  /* Purposefully using display10 weight in display20 */
  font-weight: var(--core-type-display-10-weight);
}

.display-10 {
  @extend %display-10;
}

.heading-30 {
  @extend %heading-30;
}

.heading-20 {
  @extend %heading-20;
}

.heading-10 {
  @extend %heading-10;
}

.heading-5 {
  @extend %heading-5;
}

.color-10 {
  color: var(--core-text-10-color);
}

.color-20 {
  color: var(--core-text-20-color);
}

.color-30 {
  color: var(--core-text-30-color);
}

.color-inverse {
  color: var(--core-text-inverse-color);
}

.color-black {
  color: var(--core-text-black-color);
}

.color-white {
  color: var(--core-text-white-color);
}

.color-currentColor {
  color: currentColor;
}
