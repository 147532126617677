:root {
  --dex-spacing-05x: 4px;
  --dex-spacing-1x: 8px;
  --dex-spacing-2x: 16px;
  --dex-spacing-3x: 24px;
  --dex-spacing-4x: 32px;
  --dex-spacing-5x: 40px;
  --dex-spacing-6x: 48px;
  --dex-spacing-7x: 56px;
  --dex-spacing-8x: 64px;
  --dex-spacing-9x: 72px;
  --dex-spacing-10x: 80px;

  --dex-border-color: var(--core-fill-30-color);
  --dex-border-width: 1px;
  --dex-border-style: solid;
  --dex-border-line: var(--dex-border-width) var(--dex-border-style)
    var(--dex-border-color);
  --dex-border-radius: 6px;

  --dex-top-nav-height: 60px;
}
