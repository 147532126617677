/* Dim mode color variable definitions DEX design created  */
:root {
  --core-burgundy-fill-dim-mode-color: #c6538a;
  --core-burgundy-text-dim-mode-color: #ffc1f8;
  --core-burgundy-10-dim-mode-color: #a84f7e;
  --core-burgundy-20-dim-mode-color: #bf5287;
  --core-burgundy-30-dim-mode-color: #684765;
  --core-burgundy-40-dim-mode-color: #4b4557;
  --core-text-10-dim-mode-color: rgba(255, 255, 255, 0.95);
  --core-text-20-dim-mode-color: #cbdcf1;
  --core-text-30-dim-mode-color: #a6adc2;
  --core-text-inverse-dim-mode-color: rgba(0, 0, 0, 0.9);
  --core-text-black-dim-mode-color: rgba(0, 0, 0, 0.9);
  --core-text-white-dim-mode-color: #ffffff;
  --core-fill-10-dim-mode-color: rgba(255, 255, 255, 0.95);
  --core-fill-20-dim-mode-color: #a6adc2;
  --core-fill-30-dim-mode-color: #5c6b7d;
  --core-fill-40-dim-mode-color: #273240;
  --core-fill-50-dim-mode-color: #222c38;
  --core-fill-inverse-dim-mode-color: #344151;
  --core-fill-black-dim-mode-color: #000000;
  --core-fill-white-dim-mode-color: #ffffff;
  --core-divider-10-dim-mode-color: #455162;
  --core-divider-20-dim-mode-color: #3f4b5c;
  --core-surface-5-dim-mode-color: #344151;
  --core-surface-10-dim-mode-color: #2f3b49;
  --core-surface-20-dim-mode-color: #273240;
  --core-surface-30-dim-mode-color: #222c38;
  --core-surface-inverse-dim-mode-color: #ffffff;
  --core-surface-overlay-dim-mode-color: rgba(34, 44, 56, 0.8);
  --core-emphasis-fill-dim-mode-color: #8fc9ff;
  --core-emphasis-text-dim-mode-color: #a2dcff;
  --core-emphasis-10-dim-mode-color: #7caedc;
  --core-emphasis-20-dim-mode-color: #8bc2f6;
  --core-emphasis-30-dim-mode-color: #516c89;
  --core-emphasis-40-dim-mode-color: #41556b;
  --core-success-fill-dim-mode-color: #4dc3c7;
  --core-success-text-dim-mode-color: #1adfe5;
  --core-success-10-dim-mode-color: #58d5dc;
  --core-success-20-dim-mode-color: #60f0f6;
  --core-success-30-dim-mode-color: #40707d;
  --core-success-40-dim-mode-color: #3a5766;
  --core-warning-fill-dim-mode-color: #f4a748;
  --core-warning-text-dim-mode-color: #ffb761;
  --core-warning-10-dim-mode-color: #ce9754;
  --core-warning-20-dim-mode-color: #eba755;
  --core-warning-30-dim-mode-color: #6d6252;
  --core-warning-40-dim-mode-color: #4d4f52;
  --core-critical-fill-dim-mode-color: #ff8082;
  --core-critical-text-dim-mode-color: #ffa2a5;
  --core-critical-10-dim-mode-color: #d67b80;
  --core-critical-20-dim-mode-color: #f58689;
  --core-critical-30-dim-mode-color: #705763;
  --core-critical-40-dim-mode-color: #524c5a;
  --core-red-fill-dim-mode-color: #ff8a8c;
  --core-red-text-dim-mode-color: #ffa2a5;
  --core-red-10-dim-mode-color: #d67b80;
  --core-red-20-dim-mode-color: #f58689;
  --core-red-30-dim-mode-color: #705763;
  --core-red-40-dim-mode-color: #524c5a;
  --core-orange-fill-dim-mode-color: #f25b3d;
  --core-orange-text-dim-mode-color: #ffb257;
  --core-orange-10-dim-mode-color: #bf4830;
  --core-orange-20-dim-mode-color: #db5237;
  --core-orange-30-dim-mode-color: #67484b;
  --core-orange-40-dim-mode-color: #4b444e;
  --core-gold-fill-dim-mode-color: #f5ac55;
  --core-gold-text-dim-mode-color: #ffb761;
  --core-gold-10-dim-mode-color: #ce9754;
  --core-gold-20-dim-mode-color: #eba755;
  --core-gold-30-dim-mode-color: #6d6252;
  --core-gold-40-dim-mode-color: #4d4f52;
  --core-yellow-fill-dim-mode-color: #e9c763;
  --core-yellow-text-dim-mode-color: #f1d274;
  --core-yellow-10-dim-mode-color: #c1ab63;
  --core-yellow-20-dim-mode-color: #e0c062;
  --core-yellow-30-dim-mode-color: #636357;
  --core-yellow-40-dim-mode-color: #464f53;
  --core-taupe-fill-dim-mode-color: #a67c53;
  --core-taupe-text-dim-mode-color: #fccb99;
  --core-taupe-10-dim-mode-color: #735639;
  --core-taupe-20-dim-mode-color: #8c6946;
  --core-taupe-30-dim-mode-color: #575352;
  --core-taupe-40-dim-mode-color: #434952;
  --core-brown-fill-dim-mode-color: #885e36;
  --core-brown-text-dim-mode-color: #f7cda5;
  --core-brown-10-dim-mode-color: #77583b;
  --core-brown-20-dim-mode-color: #845c37;
  --core-brown-30-dim-mode-color: #524b48;
  --core-brown-40-dim-mode-color: #40454d;
  --core-forest-fill-dim-mode-color: #27a03b;
  --core-forest-text-dim-mode-color: #74ed88;
  --core-forest-10-dim-mode-color: #27a03b;
  --core-forest-20-dim-mode-color: #27a03b;
  --core-forest-30-dim-mode-color: #315e4b;
  --core-forest-40-dim-mode-color: #324f4e;
  --core-green-fill-dim-mode-color: #61faff;
  --core-green-text-dim-mode-color: #1adfe5;
  --core-green-10-dim-mode-color: #58d5dc;
  --core-green-20-dim-mode-color: #60f0f6;
  --core-green-30-dim-mode-color: #40707d;
  --core-green-40-dim-mode-color: #3a5766;
  --core-teal-fill-dim-mode-color: #12bf94;
  --core-teal-text-dim-mode-color: #2defbe;
  --core-teal-10-dim-mode-color: #0d8c6d;
  --core-teal-20-dim-mode-color: #10a680;
  --core-teal-30-dim-mode-color: #2b6363;
  --core-teal-40-dim-mode-color: #2f525a;
  --core-blue-fill-dim-mode-color: #8fc9ff;
  --core-blue-text-dim-mode-color: #a2dcff;
  --core-blue-10-dim-mode-color: #7caedc;
  --core-blue-20-dim-mode-color: #8bc2f6;
  --core-blue-30-dim-mode-color: #516c89;
  --core-blue-40-dim-mode-color: #41556b;
  --core-sky-fill-dim-mode-color: #2693ff;
  --core-sky-text-dim-mode-color: #75e2ff;
  --core-sky-10-dim-mode-color: #1f75cc;
  --core-sky-20-dim-mode-color: #2284e5;
  --core-sky-30-dim-mode-color: #335b84;
  --core-sky-40-dim-mode-color: #324962;
  --core-purple-fill-dim-mode-color: #d7b8ff;
  --core-purple-text-dim-mode-color: #e7c8ff;
  --core-purple-10-dim-mode-color: #b6a0dc;
  --core-purple-20-dim-mode-color: #cfb2f6;
  --core-purple-30-dim-mode-color: #656585;
  --core-purple-40-dim-mode-color: #495168;
  --core-pink-fill-dim-mode-color: #ff85b4;
  --core-pink-text-dim-mode-color: #ffc1f0;
  --core-pink-10-dim-mode-color: #d677a0;
  --core-pink-20-dim-mode-color: #f581af;
  --core-pink-30-dim-mode-color: #70566f;
  --core-pink-40-dim-mode-color: #4e4659;
  --core-elevation-text-dim-mode-color: #ffffff;
  --core-elevation-10-dim-mode-color: 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 0px 4px rgba(0, 0, 0, 0.1);
  --core-elevation-20-dim-mode-color: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 2px 16px rgba(0, 0, 0, 0.1);
  --core-elevation-30-dim-mode-color: 0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 4px 32px rgba(0, 0, 0, 0.1);

  /* Custom DEX text colors */
  --dex-production-text-color: #178387;
  --dex-sandbox-text-color: #e40154;
}

/* Dim mode mappings from Market variables to DEX defined variables above  */
:root.dark,
:root.dim,
.dark-mode-theme,
.dim-mode-theme {
  --core-burgundy-fill-color: var(--core-burgundy-fill-dim-mode-color);
  --core-burgundy-text-color: var(--core-burgundy-text-dim-mode-color);
  --core-burgundy-10-color: var(--core-burgundy-10-dim-mode-color);
  --core-burgundy-20-color: var(--core-burgundy-20-dim-mode-color);
  --core-burgundy-30-color: var(--core-burgundy-30-dim-mode-color);
  --core-burgundy-40-color: var(--core-burgundy-40-dim-mode-color);
  --core-text-10-color: var(--core-text-10-dim-mode-color);
  --core-text-20-color: var(--core-text-20-dim-mode-color);
  --core-text-30-color: var(--core-text-30-dim-mode-color);
  --core-text-inverse-color: var(--core-text-inverse-dim-mode-color);
  --core-text-black-color: var(--core-text-black-dim-mode-color);
  --core-text-white-color: var(--core-text-white-dim-mode-color);
  --core-fill-10-color: var(--core-fill-10-dim-mode-color);
  --core-fill-20-color: var(--core-fill-20-dim-mode-color);
  --core-fill-30-color: var(--core-fill-30-dim-mode-color);
  --core-fill-40-color: var(--core-fill-40-dim-mode-color);
  --core-fill-50-color: var(--core-fill-50-dim-mode-color);
  --core-fill-inverse-color: var(--core-fill-inverse-dim-mode-color);
  --core-fill-black-color: var(--core-fill-black-dim-mode-color);
  --core-fill-white-color: var(--core-fill-white-dim-mode-color);
  --core-divider-10-color: var(--core-divider-10-dim-mode-color);
  --core-divider-20-color: var(--core-divider-20-dim-mode-color);
  --core-surface-5-color: var(--core-surface-5-dim-mode-color);
  --core-surface-10-color: var(--core-surface-10-dim-mode-color);
  --core-surface-20-color: var(--core-surface-20-dim-mode-color);
  --core-surface-30-color: var(--core-surface-30-dim-mode-color);
  --core-surface-inverse-color: var(--core-surface-inverse-dim-mode-color);
  --core-surface-overlay-color: var(--core-surface-overlay-dim-mode-color);
  --core-emphasis-fill-color: var(--core-emphasis-fill-dim-mode-color);
  --core-emphasis-text-color: var(--core-emphasis-text-dim-mode-color);
  --core-emphasis-10-color: var(--core-emphasis-10-dim-mode-color);
  --core-emphasis-20-color: var(--core-emphasis-20-dim-mode-color);
  --core-emphasis-30-color: var(--core-emphasis-30-dim-mode-color);
  --core-emphasis-40-color: var(--core-emphasis-40-dim-mode-color);
  --core-success-fill-color: var(--core-success-fill-dim-mode-color);
  --core-success-text-color: var(--core-success-text-dim-mode-color);
  --core-success-10-color: var(--core-success-10-dim-mode-color);
  --core-success-20-color: var(--core-success-20-dim-mode-color);
  --core-success-30-color: var(--core-success-30-dim-mode-color);
  --core-success-40-color: var(--core-success-40-dim-mode-color);
  --core-warning-fill-color: var(--core-warning-fill-dim-mode-color);
  --core-warning-text-color: var(--core-warning-text-dim-mode-color);
  --core-warning-10-color: var(--core-warning-10-dim-mode-color);
  --core-warning-20-color: var(--core-warning-20-dim-mode-color);
  --core-warning-30-color: var(--core-warning-30-dim-mode-color);
  --core-warning-40-color: var(--core-warning-40-dim-mode-color);
  --core-critical-fill-color: var(--core-critical-fill-dim-mode-color);
  --core-critical-text-color: var(--core-critical-text-dim-mode-color);
  --core-critical-10-color: var(--core-critical-10-dim-mode-color);
  --core-critical-20-color: var(--core-critical-20-dim-mode-color);
  --core-critical-30-color: var(--core-critical-30-dim-mode-color);
  --core-critical-40-color: var(--core-critical-40-dim-mode-color);
  --core-red-fill-color: var(--core-red-fill-dim-mode-color);
  --core-red-text-color: var(--core-red-text-dim-mode-color);
  --core-red-10-color: var(--core-red-10-dim-mode-color);
  --core-red-20-color: var(--core-red-20-dim-mode-color);
  --core-red-30-color: var(--core-red-30-dim-mode-color);
  --core-red-40-color: var(--core-red-40-dim-mode-color);
  --core-orange-fill-color: var(--core-orange-fill-dim-mode-color);
  --core-orange-text-color: var(--core-orange-text-dim-mode-color);
  --core-orange-10-color: var(--core-orange-10-dim-mode-color);
  --core-orange-20-color: var(--core-orange-20-dim-mode-color);
  --core-orange-30-color: var(--core-orange-30-dim-mode-color);
  --core-orange-40-color: var(--core-orange-40-dim-mode-color);
  --core-gold-fill-color: var(--core-gold-fill-dim-mode-color);
  --core-gold-text-color: var(--core-gold-text-dim-mode-color);
  --core-gold-10-color: var(--core-gold-10-dim-mode-color);
  --core-gold-20-color: var(--core-gold-20-dim-mode-color);
  --core-gold-30-color: var(--core-gold-30-dim-mode-color);
  --core-gold-40-color: var(--core-gold-40-dim-mode-color);
  --core-yellow-fill-color: var(--core-yellow-fill-dim-mode-color);
  --core-yellow-text-color: var(--core-yellow-text-dim-mode-color);
  --core-yellow-10-color: var(--core-yellow-10-dim-mode-color);
  --core-yellow-20-color: var(--core-yellow-20-dim-mode-color);
  --core-yellow-30-color: var(--core-yellow-30-dim-mode-color);
  --core-yellow-40-color: var(--core-yellow-40-dim-mode-color);
  --core-taupe-fill-color: var(--core-taupe-fill-dim-mode-color);
  --core-taupe-text-color: var(--core-taupe-text-dim-mode-color);
  --core-taupe-10-color: var(--core-taupe-10-dim-mode-color);
  --core-taupe-20-color: var(--core-taupe-20-dim-mode-color);
  --core-taupe-30-color: var(--core-taupe-30-dim-mode-color);
  --core-taupe-40-color: var(--core-taupe-40-dim-mode-color);
  --core-brown-fill-color: var(--core-brown-fill-dim-mode-color);
  --core-brown-text-color: var(--core-brown-text-dim-mode-color);
  --core-brown-10-color: var(--core-brown-10-dim-mode-color);
  --core-brown-20-color: var(--core-brown-20-dim-mode-color);
  --core-brown-30-color: var(--core-brown-30-dim-mode-color);
  --core-brown-40-color: var(--core-brown-40-dim-mode-color);
  --core-forest-fill-color: var(--core-forest-fill-dim-mode-color);
  --core-forest-text-color: var(--core-forest-text-dim-mode-color);
  --core-forest-10-color: var(--core-forest-10-dim-mode-color);
  --core-forest-20-color: var(--core-forest-20-dim-mode-color);
  --core-forest-30-color: var(--core-forest-30-dim-mode-color);
  --core-forest-40-color: var(--core-forest-40-dim-mode-color);
  --core-green-fill-color: var(--core-green-fill-dim-mode-color);
  --core-green-text-color: var(--core-green-text-dim-mode-color);
  --core-green-10-color: var(--core-green-10-dim-mode-color);
  --core-green-20-color: var(--core-green-20-dim-mode-color);
  --core-green-30-color: var(--core-green-30-dim-mode-color);
  --core-green-40-color: var(--core-green-40-dim-mode-color);
  --core-teal-fill-color: var(--core-teal-fill-dim-mode-color);
  --core-teal-text-color: var(--core-teal-text-dim-mode-color);
  --core-teal-10-color: var(--core-teal-10-dim-mode-color);
  --core-teal-20-color: var(--core-teal-20-dim-mode-color);
  --core-teal-30-color: var(--core-teal-30-dim-mode-color);
  --core-teal-40-color: var(--core-teal-40-dim-mode-color);
  --core-blue-fill-color: var(--core-blue-fill-dim-mode-color);
  --core-blue-text-color: var(--core-blue-text-dim-mode-color);
  --core-blue-10-color: var(--core-blue-10-dim-mode-color);
  --core-blue-20-color: var(--core-blue-20-dim-mode-color);
  --core-blue-30-color: var(--core-blue-30-dim-mode-color);
  --core-blue-40-color: var(--core-blue-40-dim-mode-color);
  --core-sky-fill-color: var(--core-sky-fill-dim-mode-color);
  --core-sky-text-color: var(--core-sky-text-dim-mode-color);
  --core-sky-10-color: var(--core-sky-10-dim-mode-color);
  --core-sky-20-color: var(--core-sky-20-dim-mode-color);
  --core-sky-30-color: var(--core-sky-30-dim-mode-color);
  --core-sky-40-color: var(--core-sky-40-dim-mode-color);
  --core-purple-fill-color: var(--core-purple-fill-dim-mode-color);
  --core-purple-text-color: var(--core-purple-text-dim-mode-color);
  --core-purple-10-color: var(--core-purple-10-dim-mode-color);
  --core-purple-20-color: var(--core-purple-20-dim-mode-color);
  --core-purple-30-color: var(--core-purple-30-dim-mode-color);
  --core-purple-40-color: var(--core-purple-40-dim-mode-color);
  --core-pink-fill-color: var(--core-pink-fill-dim-mode-color);
  --core-pink-text-color: var(--core-pink-text-dim-mode-color);
  --core-pink-10-color: var(--core-pink-10-dim-mode-color);
  --core-pink-20-color: var(--core-pink-20-dim-mode-color);
  --core-pink-30-color: var(--core-pink-30-dim-mode-color);
  --core-pink-40-color: var(--core-pink-40-dim-mode-color);
  --core-elevation-text-color: var(--core-elevation-text-dim-mode-color);
  --core-elevation-10-color: var(--core-elevation-10-dim-mode-color);
  --core-elevation-20-color: var(--core-elevation-20-dim-mode-color);
  --core-elevation-30-color: var(--core-elevation-30-dim-mode-color);
  /* Pills do not use core color variables, so must be mapped too */
  --pill-alpha-variant-background-color: var(--core-pink-40-dim-mode-color);
  --pill-alpha-variant-text-color: var(--core-pink-text-dim-mode-color);
  --pill-beta-variant-background-color: var(--core-teal-40-dim-mode-color);
  --pill-beta-variant-text-color: var(--core-teal-text-dim-mode-color);
  --pill-critical-variant-background-color: var(--core-red-40-dim-mode-color);
  --pill-critical-variant-text-color: var(--core-red-text-dim-mode-color);
  --pill-emphasis-variant-background-color: var(--core-blue-40-dim-mode-color);
  --pill-emphasis-variant-text-color: var(--core-blue-text-dim-mode-color);
  --pill-insight-variant-background-color: var(--core-purple-40-dim-mode-color);
  --pill-insight-variant-text-color: var(--core-purple-text-dim-mode-color);
  --pill-normal-variant-background-color: var(--core-surface-40-dim-mode-color);
  --pill-normal-variant-text-color: var(--core-surface-text-dim-mode-color);
  --pill-success-variant-background-color: var(--core-green-40-dim-mode-color);
  --pill-success-variant-text-color: var(--core-green-text-dim-mode-color);
  --pill-warning-variant-background-color: var(--core-gold-40-dim-mode-color);
  --pill-warning-variant-text-color: var(--core-gold-text-dim-mode-color);

  /* Overrides for Market Buttons */
  --button-normal-variant-primary-rank-normal-state-label-color: var(
    --core-fill-inverse-dim-mode-color
  );
  --button-normal-variant-primary-rank-hover-state-label-color: var(
    --core-fill-inverse-dim-mode-color
  );
  --button-normal-variant-primary-rank-hover-state-background-color: var(
    --core-emphasis-20-dim-mode-color
  );

  /* Overrides for Popover */
  --modal-popover-background-color: var(--core-surface-5-dim-mode-color);

  /* We additionally override the light mode colors because market uses the light mode colors
  specifically in their base theme. So there's many one-off colors we need to override
  like the --modal-background-color which targets the light mode color. */
  --core-burgundy-fill-light-mode-color: var(
    --core-burgundy-fill-dim-mode-color
  );
  --core-burgundy-text-light-mode-color: var(
    --core-burgundy-text-dim-mode-color
  );
  --core-burgundy-10-light-mode-color: var(--core-burgundy-10-dim-mode-color);
  --core-burgundy-20-light-mode-color: var(--core-burgundy-20-dim-mode-color);
  --core-burgundy-30-light-mode-color: var(--core-burgundy-30-dim-mode-color);
  --core-burgundy-40-light-mode-color: var(--core-burgundy-40-dim-mode-color);
  --core-text-10-light-mode-color: var(--core-text-10-dim-mode-color);
  --core-text-20-light-mode-color: var(--core-text-20-dim-mode-color);
  --core-text-30-light-mode-color: var(--core-text-30-dim-mode-color);
  --core-text-inverse-light-mode-color: var(--core-text-inverse-dim-mode-color);
  --core-text-black-light-mode-color: var(--core-text-black-dim-mode-color);
  --core-text-white-light-mode-color: var(--core-text-white-dim-mode-color);
  --core-fill-10-light-mode-color: var(--core-fill-10-dim-mode-color);
  --core-fill-20-light-mode-color: var(--core-fill-20-dim-mode-color);
  --core-fill-30-light-mode-color: var(--core-fill-30-dim-mode-color);
  --core-fill-40-light-mode-color: var(--core-fill-40-dim-mode-color);
  --core-fill-50-light-mode-color: var(--core-fill-50-dim-mode-color);
  --core-fill-inverse-light-mode-color: var(--core-fill-inverse-dim-mode-color);
  --core-fill-black-light-mode-color: var(--core-fill-black-dim-mode-color);
  --core-fill-white-light-mode-color: var(--core-fill-white-dim-mode-color);
  --core-divider-10-light-mode-color: var(--core-divider-10-dim-mode-color);
  --core-divider-20-light-mode-color: var(--core-divider-20-dim-mode-color);
  --core-surface-5-light-mode-color: var(--core-surface-5-dim-mode-color);
  --core-surface-10-light-mode-color: var(--core-surface-10-dim-mode-color);
  --core-surface-20-light-mode-color: var(--core-surface-20-dim-mode-color);
  --core-surface-30-light-mode-color: var(--core-surface-30-dim-mode-color);
  --core-surface-inverse-light-mode-color: var(
    --core-surface-inverse-dim-mode-color
  );
  --core-surface-overlay-light-mode-color: var(
    --core-surface-overlay-dim-mode-color
  );
  --core-emphasis-fill-light-mode-color: var(
    --core-emphasis-fill-dim-mode-color
  );
  --core-emphasis-text-light-mode-color: var(
    --core-emphasis-text-dim-mode-color
  );
  --core-emphasis-10-light-mode-color: var(--core-emphasis-10-dim-mode-color);
  --core-emphasis-20-light-mode-color: var(--core-emphasis-20-dim-mode-color);
  --core-emphasis-30-light-mode-color: var(--core-emphasis-30-dim-mode-color);
  --core-emphasis-40-light-mode-color: var(--core-emphasis-40-dim-mode-color);
  --core-success-fill-light-mode-color: var(--core-success-fill-dim-mode-color);
  --core-success-text-light-mode-color: var(--core-success-text-dim-mode-color);
  --core-success-10-light-mode-color: var(--core-success-10-dim-mode-color);
  --core-success-20-light-mode-color: var(--core-success-20-dim-mode-color);
  --core-success-30-light-mode-color: var(--core-success-30-dim-mode-color);
  --core-success-40-light-mode-color: var(--core-success-40-dim-mode-color);
  --core-warning-fill-light-mode-color: var(--core-warning-fill-dim-mode-color);
  --core-warning-text-light-mode-color: var(--core-warning-text-dim-mode-color);
  --core-warning-10-light-mode-color: var(--core-warning-10-dim-mode-color);
  --core-warning-20-light-mode-color: var(--core-warning-20-dim-mode-color);
  --core-warning-30-light-mode-color: var(--core-warning-30-dim-mode-color);
  --core-warning-40-light-mode-color: var(--core-warning-40-dim-mode-color);
  --core-critical-fill-light-mode-color: var(
    --core-critical-fill-dim-mode-color
  );
  --core-critical-text-light-mode-color: var(
    --core-critical-text-dim-mode-color
  );
  --core-critical-10-light-mode-color: var(--core-critical-10-dim-mode-color);
  --core-critical-20-light-mode-color: var(--core-critical-20-dim-mode-color);
  --core-critical-30-light-mode-color: var(--core-critical-30-dim-mode-color);
  --core-critical-40-light-mode-color: var(--core-critical-40-dim-mode-color);
  --core-red-fill-light-mode-color: var(--core-red-fill-dim-mode-color);
  --core-red-text-light-mode-color: var(--core-red-text-dim-mode-color);
  --core-red-10-light-mode-color: var(--core-red-10-dim-mode-color);
  --core-red-20-light-mode-color: var(--core-red-20-dim-mode-color);
  --core-red-30-light-mode-color: var(--core-red-30-dim-mode-color);
  --core-red-40-light-mode-color: var(--core-red-40-dim-mode-color);
  --core-orange-fill-light-mode-color: var(--core-orange-fill-dim-mode-color);
  --core-orange-text-light-mode-color: var(--core-orange-text-dim-mode-color);
  --core-orange-10-light-mode-color: var(--core-orange-10-dim-mode-color);
  --core-orange-20-light-mode-color: var(--core-orange-20-dim-mode-color);
  --core-orange-30-light-mode-color: var(--core-orange-30-dim-mode-color);
  --core-orange-40-light-mode-color: var(--core-orange-40-dim-mode-color);
  --core-gold-fill-light-mode-color: var(--core-gold-fill-dim-mode-color);
  --core-gold-text-light-mode-color: var(--core-gold-text-dim-mode-color);
  --core-gold-10-light-mode-color: var(--core-gold-10-dim-mode-color);
  --core-gold-20-light-mode-color: var(--core-gold-20-dim-mode-color);
  --core-gold-30-light-mode-color: var(--core-gold-30-dim-mode-color);
  --core-gold-40-light-mode-color: var(--core-gold-40-dim-mode-color);
  --core-yellow-fill-light-mode-color: var(--core-yellow-fill-dim-mode-color);
  --core-yellow-text-light-mode-color: var(--core-yellow-text-dim-mode-color);
  --core-yellow-10-light-mode-color: var(--core-yellow-10-dim-mode-color);
  --core-yellow-20-light-mode-color: var(--core-yellow-20-dim-mode-color);
  --core-yellow-30-light-mode-color: var(--core-yellow-30-dim-mode-color);
  --core-yellow-40-light-mode-color: var(--core-yellow-40-dim-mode-color);
  --core-taupe-fill-light-mode-color: var(--core-taupe-fill-dim-mode-color);
  --core-taupe-text-light-mode-color: var(--core-taupe-text-dim-mode-color);
  --core-taupe-10-light-mode-color: var(--core-taupe-10-dim-mode-color);
  --core-taupe-20-light-mode-color: var(--core-taupe-20-dim-mode-color);
  --core-taupe-30-light-mode-color: var(--core-taupe-30-dim-mode-color);
  --core-taupe-40-light-mode-color: var(--core-taupe-40-dim-mode-color);
  --core-brown-fill-light-mode-color: var(--core-brown-fill-dim-mode-color);
  --core-brown-text-light-mode-color: var(--core-brown-text-dim-mode-color);
  --core-brown-10-light-mode-color: var(--core-brown-10-dim-mode-color);
  --core-brown-20-light-mode-color: var(--core-brown-20-dim-mode-color);
  --core-brown-30-light-mode-color: var(--core-brown-30-dim-mode-color);
  --core-brown-40-light-mode-color: var(--core-brown-40-dim-mode-color);
  --core-forest-fill-light-mode-color: var(--core-forest-fill-dim-mode-color);
  --core-forest-text-light-mode-color: var(--core-forest-text-dim-mode-color);
  --core-forest-10-light-mode-color: var(--core-forest-10-dim-mode-color);
  --core-forest-20-light-mode-color: var(--core-forest-20-dim-mode-color);
  --core-forest-30-light-mode-color: var(--core-forest-30-dim-mode-color);
  --core-forest-40-light-mode-color: var(--core-forest-40-dim-mode-color);
  --core-green-fill-light-mode-color: var(--core-green-fill-dim-mode-color);
  --core-green-text-light-mode-color: var(--core-green-text-dim-mode-color);
  --core-green-10-light-mode-color: var(--core-green-10-dim-mode-color);
  --core-green-20-light-mode-color: var(--core-green-20-dim-mode-color);
  --core-green-30-light-mode-color: var(--core-green-30-dim-mode-color);
  --core-green-40-light-mode-color: var(--core-green-40-dim-mode-color);
  --core-teal-fill-light-mode-color: var(--core-teal-fill-dim-mode-color);
  --core-teal-text-light-mode-color: var(--core-teal-text-dim-mode-color);
  --core-teal-10-light-mode-color: var(--core-teal-10-dim-mode-color);
  --core-teal-20-light-mode-color: var(--core-teal-20-dim-mode-color);
  --core-teal-30-light-mode-color: var(--core-teal-30-dim-mode-color);
  --core-teal-40-light-mode-color: var(--core-teal-40-dim-mode-color);
  --core-blue-fill-light-mode-color: var(--core-blue-fill-dim-mode-color);
  --core-blue-text-light-mode-color: var(--core-blue-text-dim-mode-color);
  --core-blue-10-light-mode-color: var(--core-blue-10-dim-mode-color);
  --core-blue-20-light-mode-color: var(--core-blue-20-dim-mode-color);
  --core-blue-30-light-mode-color: var(--core-blue-30-dim-mode-color);
  --core-blue-40-light-mode-color: var(--core-blue-40-dim-mode-color);
  --core-sky-fill-light-mode-color: var(--core-sky-fill-dim-mode-color);
  --core-sky-text-light-mode-color: var(--core-sky-text-dim-mode-color);
  --core-sky-10-light-mode-color: var(--core-sky-10-dim-mode-color);
  --core-sky-20-light-mode-color: var(--core-sky-20-dim-mode-color);
  --core-sky-30-light-mode-color: var(--core-sky-30-dim-mode-color);
  --core-sky-40-light-mode-color: var(--core-sky-40-dim-mode-color);
  --core-purple-fill-light-mode-color: var(--core-purple-fill-dim-mode-color);
  --core-purple-text-light-mode-color: var(--core-purple-text-dim-mode-color);
  --core-purple-10-light-mode-color: var(--core-purple-10-dim-mode-color);
  --core-purple-20-light-mode-color: var(--core-purple-20-dim-mode-color);
  --core-purple-30-light-mode-color: var(--core-purple-30-dim-mode-color);
  --core-purple-40-light-mode-color: var(--core-purple-40-dim-mode-color);
  --core-pink-fill-light-mode-color: var(--core-pink-fill-dim-mode-color);
  --core-pink-text-light-mode-color: var(--core-pink-text-dim-mode-color);
  --core-pink-10-light-mode-color: var(--core-pink-10-dim-mode-color);
  --core-pink-20-light-mode-color: var(--core-pink-20-dim-mode-color);
  --core-pink-30-light-mode-color: var(--core-pink-30-dim-mode-color);
  --core-pink-40-light-mode-color: var(--core-pink-40-dim-mode-color);
  --core-elevation-text-light-mode-color: var(
    --core-elevation-text-dim-mode-color
  );
  --core-elevation-10-light-mode-color: var(--core-elevation-10-dim-mode-color);
  --core-elevation-20-light-mode-color: var(--core-elevation-20-dim-mode-color);
  --core-elevation-30-light-mode-color: var(--core-elevation-30-dim-mode-color);

  /* Custom rgba colours */
  /* --core-surface-5-color */
  --dex-background-color-rbga: rgba(52, 65, 81, 1);
  --dex-background-color-rbga-transparent: rgba(52, 65, 81, 0);
  --dex-code-block-border-color: transparent;
  --dex-shim-linear-gradient-colors: rgba(0, 0, 0, 0.08) -1.25%,
    rgba(0, 0, 0, 0) 100%;

  --core-fill-50-solid-color: var(--core-fill-50-color);

  /* this is light mode fill 30 */
  --dex-preview-tooltip-divider-color: rgba(0, 0, 0, 0.15);

  /* Custom DEX text colors */
  --dex-production-text-color: #61faff;
  --dex-sandbox-text-color: #ff85b4;
}

:root,
::before,
::after {
  --core-focus-ring-border-dim-mode: 2px solid #8fc9ff;
  --core-focus-ring-border-light-mode: var(--core-focus-ring-border-dim-mode);
}
