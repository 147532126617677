@value bp-tablet-small-viewport, bp-desktop-medium-viewport  from '../../../ui/src/lib/styles/values/breakpoints.css';

.single-column {
  margin: 0 auto;
  max-width: var(--dex-grid-template-columns);
  padding-left: 0;
}

@media only screen and bp-tablet-small-viewport {
  /* Ensures there's a gap between the left navigation and content
  when the screen shrinks */
  .single-column {
    padding-left: calc(7 * var(--dex-spacing-1x));
  }
}

@media only screen and bp-desktop-medium-viewport {
  /* Ensures there's a gap between the left navigation and content
  when the screen shrinks */
  .single-column {
    padding-left: 0;
  }
}
