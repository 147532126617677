.grid-item-small {
  grid-column: auto / span var(--dex-grid-item-small-size-column-span);
}

.grid-item-medium {
  grid-column: auto / span var(--dex-grid-item-medium-size-column-span);
}

.grid-item-full {
  grid-column: auto / span var(--dex-grid-item-full-size-column-span);
}

.grid-item-across {
  grid-column: 1 / -1;
}
