@value bp-tablet-small-viewport, bp-tablet-medium-viewport, bp-desktop-medium-viewport, bp-desktop-large-viewport from '../../../ui/src/lib/styles/values/breakpoints.css';

/* Smallest viewport is default */
.dex-app-grid-container {
  /* When no content keep the width to the container. Only put this in the app container,
  not the content container, otherwise it'll break the margins */
  width: 100%;

  --dex-grid-template-columns: var(--dex-app-grid-template-columns-small);
  --dex-grid-margin-left: var(--dex-app-grid-margin-left-small);
  --dex-grid-margin-right: var(--dex-app-grid-margin-right-small);
  --dex-grid-column-gap: var(--dex-app-grid-column-gap-small);
  --dex-grid-row-gap: var(--dex-app-grid-row-gap-small);
  --dex-grid-item-full-size-column-span: var(
    --dex-app-grid-item-full-size-column-span-small
  );
  --dex-grid-item-medium-size-column-span: var(
    --dex-app-grid-item-medium-size-column-span-small
  );
  --dex-grid-item-small-size-column-span: var(
    --dex-app-grid-item-small-size-column-span-small
  );
}

.dex-docs-app-grid-container {
  --dex-grid-template-columns: var(--dex-docs-app-grid-template-columns-small);
  --dex-grid-margin-left: var(--dex-docs-app-grid-margin-left-small);
  --dex-grid-margin-right: var(--dex-docs-app-grid-margin-right-small);
  --dex-grid-column-gap: var(--dex-docs-app-grid-column-gap-small);
  --dex-grid-row-gap: var(--dex-docs-app-grid-row-gap-small);
  --dex-grid-item-full-size-column-span: var(
    --dex-docs-app-grid-item-full-size-column-span-small
  );
  --dex-grid-item-medium-size-column-span: var(
    --dex-docs-app-grid-item-medium-size-column-span-small
  );
  --dex-grid-item-small-size-column-span: var(
    --dex-docs-app-grid-item-small-size-column-span-small
  );
}

.dex-content-grid-container {
  --dex-grid-template-columns: var(--dex-content-grid-template-columns-small);
  --dex-grid-margin-left: var(--dex-content-grid-margin-left-small);
  --dex-grid-margin-right: var(--dex-content-grid-margin-right-small);
  --dex-grid-column-gap: var(--dex-content-grid-column-gap-small);
  --dex-grid-row-gap: var(--dex-content-grid-row-gap-small);
  --dex-grid-item-full-size-column-span: var(
    --dex-content-grid-item-full-size-column-span-small
  );
  --dex-grid-item-medium-size-column-span: var(
    --dex-content-grid-item-medium-size-column-span-small
  );
  --dex-grid-item-small-size-column-span: var(
    --dex-content-grid-item-small-size-column-span-small
  );
}

.dex-docs-content-grid-container {
  --dex-grid-template-columns: var(
    --dex-docs-content-grid-template-columns-small
  );
  --dex-grid-margin-left: var(--dex-docs-content-grid-margin-left-small);
  --dex-grid-margin-right: var(--dex-docs-content-grid-margin-right-small);
  --dex-grid-column-gap: var(--dex-docs-content-grid-column-gap-small);
  --dex-grid-row-gap: var(--dex-docs-content-grid-row-gap-small);
  --dex-grid-item-full-size-column-span: var(
    --dex-docs-content-grid-item-full-size-column-span-small
  );
  --dex-grid-item-medium-size-column-span: var(
    --dex-docs-content-grid-item-medium-size-column-span-small
  );
  --dex-grid-item-small-size-column-span: var(
    --dex-docs-content-grid-item-small-size-column-span-small
  );
}

.dex-devs-console-app-grid-container {
  --dex-grid-template-columns: var(
    --dex-devs-console-app-grid-template-columns-small
  );
  --dex-grid-margin-left: var(--dex-devs-console-app-grid-margin-left-small);
  --dex-grid-margin-right: var(--dex-devs-console-app-grid-margin-right-small);
  --dex-grid-column-gap: var(--dex-devs-console-app-grid-column-gap-small);
  --dex-grid-row-gap: var(--dex-devs-console-app-grid-row-gap-small);
  --dex-grid-item-full-size-column-span: var(
    --dex-devs-console-app-grid-item-full-size-column-span-small
  );
  --dex-grid-item-medium-size-column-span: var(
    --dex-devs-console-app-grid-item-medium-size-column-span-small
  );
  --dex-grid-item-small-size-column-span: var(
    --dex-devs-console-app-grid-item-small-size-column-span-small
  );
}

.dex-left-ftoc-grid-container {
  --dex-grid-template-columns: var(--dex-left-ftoc-grid-template-columns-small);
  --dex-grid-margin-left: var(--dex-left-ftoc-grid-margin-left-small);
  --dex-grid-margin-right: var(--dex-left-ftoc-grid-margin-right-small);
  --dex-grid-column-gap: var(--dex-left-ftoc-grid-column-gap-small);
  --dex-grid-row-gap: var(--dex-left-ftoc-grid-row-gap-small);
}

.dex-grid-container {
  display: grid;
  grid-template-columns: var(--dex-grid-template-columns);
  margin-left: var(--dex-grid-margin-left);
  margin-right: var(--dex-grid-margin-right);
  column-gap: var(--dex-grid-column-gap);
  row-gap: var(--dex-grid-row-gap);
  transition: margin-left var(--dex-docs-layout-transition-timing);
}

/* Medium Viewports */
@media only screen and bp-tablet-small-viewport {
  .dex-app-grid-container {
    --dex-grid-template-columns: var(--dex-app-grid-template-columns-medium);
    --dex-grid-margin-left: var(--dex-app-grid-margin-left-medium);
    --dex-grid-margin-right: var(--dex-app-grid-margin-right-medium);
    --dex-grid-column-gap: var(--dex-app-grid-column-gap-medium);
    --dex-grid-row-gap: var(--dex-app-grid-row-gap-medium);

    --dex-grid-item-full-size-column-span: var(
      --dex-app-grid-item-full-size-column-span-medium
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-app-grid-item-medium-size-column-span-medium
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-app-grid-item-small-size-column-span-medium
    );
  }

  .dex-devs-console-app-grid-container {
    --dex-grid-template-columns: var(
      --dex-devs-console-app-grid-template-columns-medium
    );
    --dex-grid-margin-left: var(--dex-devs-console-app-grid-margin-left-medium);
    --dex-grid-margin-right: var(
      --dex-devs-console-app-grid-margin-right-medium
    );
    --dex-grid-column-gap: var(--dex-devs-console-app-grid-column-gap-medium);
    --dex-grid-row-gap: var(--dex-devs-console-app-grid-row-gap-medium);

    --dex-grid-item-full-size-column-span: var(
      --dex-devs-console-app-grid-item-full-size-column-span-medium
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-devs-console-app-grid-item-medium-size-column-span-medium
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-devs-console-app-grid-item-small-size-column-span-medium
    );
  }

  .dex-content-grid-container {
    --dex-grid-template-columns: var(
      --dex-content-grid-template-columns-medium
    );
    --dex-grid-margin-left: var(--dex-content-grid-margin-left-medium);
    --dex-grid-margin-right: var(--dex-content-grid-margin-right-medium);
    --dex-grid-column-gap: var(--dex-content-grid-column-gap-medium);
    --dex-grid-row-gap: var(--dex-content-grid-row-gap-medium);

    --dex-grid-item-full-size-column-span: var(
      --dex-content-grid-item-full-size-column-span-medium
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-content-grid-item-medium-size-column-span-medium
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-content-grid-item-small-size-column-span-medium
    );
  }
}

/* Docs grids use this as the medium viewport */
@media only screen and bp-tablet-medium-viewport {
  .dex-docs-app-grid-container {
    --dex-grid-template-columns: var(
      --dex-docs-app-grid-template-columns-medium
    );
    --dex-grid-margin-left: var(--dex-docs-app-grid-margin-left-medium);
    --dex-grid-margin-right: var(--dex-docs-app-grid-margin-right-medium);
    --dex-grid-column-gap: var(--dex-docs-app-grid-column-gap-medium);
    --dex-grid-row-gap: var(--dex-docs-app-grid-row-gap-medium);

    --dex-grid-item-full-size-column-span: var(
      --dex-docs-app-grid-item-full-size-column-span-medium
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-docs-app-grid-item-medium-size-column-span-medium
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-docs-app-grid-item-small-size-column-span-medium
    );
  }

  .dex-docs-content-grid-container {
    --dex-grid-template-columns: var(
      --dex-docs-content-grid-template-columns-medium
    );
    --dex-grid-margin-left: var(--dex-docs-content-grid-margin-left-medium);
    --dex-grid-margin-right: var(--dex-docs-content-grid-margin-right-medium);
    --dex-grid-column-gap: var(--dex-docs-content-grid-column-gap-medium);
    --dex-grid-row-gap: var(--dex-docs-content-grid-row-gap-medium);

    --dex-grid-item-full-size-column-span: var(
      --dex-docs-content-grid-item-full-size-column-span-medium
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-docs-content-grid-item-medium-size-column-span-medium
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-docs-content-grid-item-small-size-column-span-medium
    );
  }

  .dex-left-ftoc-grid-container {
    --dex-grid-template-columns: var(
      --dex-left-ftoc-grid-template-columns-medium
    );
    --dex-grid-margin-left: var(--dex-left-ftoc-grid-margin-left-medium);
    --dex-grid-margin-right: var(--dex-left-ftoc-grid-margin-right-medium);
    --dex-grid-column-gap: var(--dex-left-ftoc-grid-column-gap-medium);
    --dex-grid-row-gap: var(--dex-left-ftoc-grid-row-gap-medium);
  }
}

/* Large Viewports */
@media only screen and bp-desktop-medium-viewport {
  .dex-app-grid-container {
    --dex-grid-template-columns: var(--dex-app-grid-template-columns-large);
    --dex-grid-margin-left: var(--dex-app-grid-margin-left-large);
    --dex-grid-margin-right: var(--dex-app-grid-margin-right-large);
    --dex-grid-column-gap: var(--dex-app-grid-column-gap-large);
    --dex-grid-row-gap: var(--dex-app-grid-row-gap-large);

    --dex-grid-item-full-size-column-span: var(
      --dex-app-grid-item-full-size-column-span-large
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-app-grid-item-medium-size-column-span-large
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-app-grid-item-small-size-column-span-large
    );
  }

  .dex-content-grid-container {
    --dex-grid-template-columns: var(--dex-content-grid-template-columns-large);
    --dex-grid-margin-left: var(--dex-content-grid-margin-left-large);
    --dex-grid-margin-right: var(--dex-content-grid-margin-right-large);
    --dex-grid-column-gap: var(--dex-content-grid-column-gap-large);
    --dex-grid-row-gap: var(--dex-content-grid-row-gap-large);

    --dex-grid-item-full-size-column-span: var(
      --dex-content-grid-item-full-size-column-span-large
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-content-grid-item-medium-size-column-span-large
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-content-grid-item-small-size-column-span-large
    );
  }

  .dex-content-single-grid-container {
    --dex-grid-template-columns: 800px;
    --dex-grid-margin-left: var(--dex-content-grid-margin-left-large);
    --dex-grid-margin-right: var(--dex-content-grid-margin-right-large);
    --dex-grid-column-gap: var(--dex-content-grid-column-gap-large);
    --dex-grid-row-gap: var(--dex-content-grid-row-gap-large);

    --dex-grid-item-full-size-column-span: 1;
    --dex-grid-item-medium-size-column-span: var(
      --dex-content-grid-item-medium-size-column-span-large
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-content-grid-item-small-size-column-span-large
    );
  }

  .dex-app-single-grid-container {
    --dex-grid-template-columns: 700px;
    --dex-grid-column-gap: var(--dex-content-grid-column-gap-large);
    --dex-grid-row-gap: var(--dex-content-grid-row-gap-large);

    --dex-grid-item-full-size-column-span: 1;
    --dex-grid-item-medium-size-column-span: var(
      --dex-content-grid-item-medium-size-column-span-large
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-content-grid-item-small-size-column-span-large
    );
  }

  .dex-docs-app-grid-container {
    --dex-grid-template-columns: var(
      --dex-docs-app-grid-template-columns-large
    );
    --dex-grid-margin-left: var(--dex-docs-app-grid-margin-left-large);
    --dex-grid-margin-right: var(--dex-docs-app-grid-margin-right-large);
    --dex-grid-column-gap: var(--dex-docs-app-grid-column-gap-large);
    --dex-grid-row-gap: var(--dex-docs-app-grid-row-gap-large);

    --dex-grid-item-full-size-column-span: var(
      --dex-docs-app-grid-item-full-size-column-span-large
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-docs-app-grid-item-medium-size-column-span-large
    );
    --dex-docs-grid-item-small-size-column-span: var(
      --dex-docs-app-grid-item-small-size-column-span-large
    );
  }

  .dex-docs-content-grid-container {
    --dex-grid-template-columns: var(
      --dex-docs-content-grid-template-columns-large
    );
    --dex-grid-margin-left: var(--dex-docs-content-grid-margin-left-medium);
    --dex-grid-margin-right: var(--dex-docs-content-grid-margin-right-medium);
    --dex-grid-column-gap: var(--dex-docs-content-grid-column-gap-large);
    --dex-grid-row-gap: var(--dex-docs-content-grid-row-gap-large);

    --dex-grid-item-full-size-column-span: var(
      --dex-docs-content-grid-item-full-size-column-span-large
    );
    --dex-grid-item-medium-size-column-span: var(
      --dex-docs-content-grid-item-medium-size-column-span-large
    );
    --dex-grid-item-small-size-column-span: var(
      --dex-docs-content-grid-item-small-size-column-span-large
    );
  }
}

@media only screen and bp-desktop-large-viewport {
  .dex-docs-content-grid-container {
    --dex-grid-margin-left: var(--dex-docs-content-grid-margin-left-large);
    --dex-grid-margin-right: var(--dex-docs-content-grid-margin-right-large);
  }
}
