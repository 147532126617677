.market-button svg {
  /* Market is injecting 0.125em to the bottom of SVGs in the icon slot for
  buttons. Overriding that here. */
  bottom: 0;
}

.market-pill-orange {
  --pill-warning-variant-background-color: var(--core-orange-40-color);
  --pill-warning-variant-text-color: var(--core-orange-text-color);
}
