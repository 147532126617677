.m-t-025x {
  margin-top: calc(0.25 * var(--dex-spacing-1x));
}

.m-t-05x {
  margin-top: calc(0.5 * var(--dex-spacing-1x));
}

.m-t-075x {
  margin-top: calc(0.75 * var(--dex-spacing-1x));
}

.m-t-1x {
  margin-top: calc(1 * var(--dex-spacing-1x));
}

.m-t-15x {
  margin-top: calc(1.5 * var(--dex-spacing-1x));
}

.m-t-2x {
  margin-top: calc(2 * var(--dex-spacing-1x));
}

.m-t-3x {
  margin-top: calc(3 * var(--dex-spacing-1x));
}

.m-t-4x {
  margin-top: calc(4 * var(--dex-spacing-1x));
}

.m-t-5x {
  margin-top: calc(5 * var(--dex-spacing-1x));
}

.m-t-6x {
  margin-top: calc(6 * var(--dex-spacing-1x));
}

.m-t-7x {
  margin-top: calc(7 * var(--dex-spacing-1x));
}

.m-t-8x {
  margin-top: calc(8 * var(--dex-spacing-1x));
}

.m-t-9x {
  margin-top: calc(9 * var(--dex-spacing-1x));
}

.m-t-10x {
  margin-top: calc(10 * var(--dex-spacing-1x));
}

.m-b-025x {
  margin-bottom: calc(0.25 * var(--dex-spacing-1x));
}

.m-b-05x {
  margin-bottom: calc(0.5 * var(--dex-spacing-1x));
}

.m-b-075x {
  margin-bottom: calc(0.75 * var(--dex-spacing-1x));
}

.m-b-1x {
  margin-bottom: calc(1 * var(--dex-spacing-1x));
}

.m-b-15x {
  margin-bottom: calc(1.5 * var(--dex-spacing-1x));
}

.m-b-2x {
  margin-bottom: calc(2 * var(--dex-spacing-1x));
}

.m-b-3x {
  margin-bottom: calc(3 * var(--dex-spacing-1x));
}

.m-b-4x {
  margin-bottom: calc(4 * var(--dex-spacing-1x));
}

.m-b-5x {
  margin-bottom: calc(5 * var(--dex-spacing-1x));
}

.m-b-6x {
  margin-bottom: calc(6 * var(--dex-spacing-1x));
}

.m-b-7x {
  margin-bottom: calc(7 * var(--dex-spacing-1x));
}

.m-b-8x {
  margin-bottom: calc(8 * var(--dex-spacing-1x));
}

.m-b-9x {
  margin-bottom: calc(9 * var(--dex-spacing-1x));
}

.m-b-10x {
  margin-bottom: calc(10 * var(--dex-spacing-1x));
}

.m-l-025x {
  margin-left: calc(0.25 * var(--dex-spacing-1x));
}

.m-l-05x {
  margin-left: calc(0.5 * var(--dex-spacing-1x));
}

.m-l-075x {
  margin-left: calc(0.75 * var(--dex-spacing-1x));
}

.m-l-1x {
  margin-left: calc(1 * var(--dex-spacing-1x));
}

.m-l-15x {
  margin-left: calc(1.5 * var(--dex-spacing-1x));
}

.m-l-2x {
  margin-left: calc(2 * var(--dex-spacing-1x));
}

.m-l-3x {
  margin-left: calc(3 * var(--dex-spacing-1x));
}

.m-l-4x {
  margin-left: calc(4 * var(--dex-spacing-1x));
}

.m-l-5x {
  margin-left: calc(5 * var(--dex-spacing-1x));
}

.m-l-6x {
  margin-left: calc(6 * var(--dex-spacing-1x));
}

.m-l-7x {
  margin-left: calc(7 * var(--dex-spacing-1x));
}

.m-l-8x {
  margin-left: calc(8 * var(--dex-spacing-1x));
}

.m-l-9x {
  margin-left: calc(9 * var(--dex-spacing-1x));
}

.m-l-10x {
  margin-left: calc(10 * var(--dex-spacing-1x));
}

.m-r-025x {
  margin-right: calc(0.25 * var(--dex-spacing-1x));
}

.m-r-05x {
  margin-right: calc(0.5 * var(--dex-spacing-1x));
}

.m-r-075x {
  margin-right: calc(0.75 * var(--dex-spacing-1x));
}

.m-r-1x {
  margin-right: calc(1 * var(--dex-spacing-1x));
}

.m-r-15x {
  margin-right: calc(1.5 * var(--dex-spacing-1x));
}

.m-r-2x {
  margin-right: calc(2 * var(--dex-spacing-1x));
}

.m-r-3x {
  margin-right: calc(3 * var(--dex-spacing-1x));
}

.m-r-4x {
  margin-right: calc(4 * var(--dex-spacing-1x));
}

.m-r-5x {
  margin-right: calc(5 * var(--dex-spacing-1x));
}

.m-r-6x {
  margin-right: calc(6 * var(--dex-spacing-1x));
}

.m-r-7x {
  margin-right: calc(7 * var(--dex-spacing-1x));
}

.m-r-8x {
  margin-right: calc(8 * var(--dex-spacing-1x));
}

.m-r-9x {
  margin-right: calc(9 * var(--dex-spacing-1x));
}

.m-r-10x {
  margin-right: calc(10 * var(--dex-spacing-1x));
}

.p-t-025x {
  padding-top: calc(0.25 * var(--dex-spacing-1x));
}

.p-t-05x {
  padding-top: calc(0.5 * var(--dex-spacing-1x));
}

.p-t-075x {
  padding-top: calc(0.75 * var(--dex-spacing-1x));
}

.p-t-1x {
  padding-top: calc(1 * var(--dex-spacing-1x));
}

.p-t-15x {
  padding-top: calc(1.5 * var(--dex-spacing-1x));
}

.p-t-2x {
  padding-top: calc(2 * var(--dex-spacing-1x));
}

.p-t-3x {
  padding-top: calc(3 * var(--dex-spacing-1x));
}

.p-t-4x {
  padding-top: calc(4 * var(--dex-spacing-1x));
}

.p-t-5x {
  padding-top: calc(5 * var(--dex-spacing-1x));
}

.p-t-6x {
  padding-top: calc(6 * var(--dex-spacing-1x));
}

.p-t-7x {
  padding-top: calc(7 * var(--dex-spacing-1x));
}

.p-t-8x {
  padding-top: calc(8 * var(--dex-spacing-1x));
}

.p-t-9x {
  padding-top: calc(9 * var(--dex-spacing-1x));
}

.p-t-10x {
  padding-top: calc(10 * var(--dex-spacing-1x));
}

.p-b-025x {
  padding-bottom: calc(0.25 * var(--dex-spacing-1x));
}

.p-b-05x {
  padding-bottom: calc(0.5 * var(--dex-spacing-1x));
}

.p-b-075x {
  padding-bottom: calc(0.75 * var(--dex-spacing-1x));
}

.p-b-1x {
  padding-bottom: calc(1 * var(--dex-spacing-1x));
}

.p-b-15x {
  padding-bottom: calc(1.5 * var(--dex-spacing-1x));
}

.p-b-2x {
  padding-bottom: calc(2 * var(--dex-spacing-1x));
}

.p-b-3x {
  padding-bottom: calc(3 * var(--dex-spacing-1x));
}

.p-b-4x {
  padding-bottom: calc(4 * var(--dex-spacing-1x));
}

.p-b-5x {
  padding-bottom: calc(5 * var(--dex-spacing-1x));
}

.p-b-6x {
  padding-bottom: calc(6 * var(--dex-spacing-1x));
}

.p-b-7x {
  padding-bottom: calc(7 * var(--dex-spacing-1x));
}

.p-b-8x {
  padding-bottom: calc(8 * var(--dex-spacing-1x));
}

.p-b-9x {
  padding-bottom: calc(9 * var(--dex-spacing-1x));
}

.p-b-10x {
  padding-bottom: calc(10 * var(--dex-spacing-1x));
}

.p-l-025x {
  padding-left: calc(0.25 * var(--dex-spacing-1x));
}

.p-l-05x {
  padding-left: calc(0.5 * var(--dex-spacing-1x));
}

.p-l-075x {
  padding-left: calc(0.75 * var(--dex-spacing-1x));
}

.p-l-1x {
  padding-left: calc(1 * var(--dex-spacing-1x));
}

.p-l-15x {
  padding-left: calc(1.5 * var(--dex-spacing-1x));
}

.p-l-2x {
  padding-left: calc(2 * var(--dex-spacing-1x));
}

.p-l-3x {
  padding-left: calc(3 * var(--dex-spacing-1x));
}

.p-l-4x {
  padding-left: calc(4 * var(--dex-spacing-1x));
}

.p-l-5x {
  padding-left: calc(5 * var(--dex-spacing-1x));
}

.p-l-6x {
  padding-left: calc(6 * var(--dex-spacing-1x));
}

.p-l-7x {
  padding-left: calc(7 * var(--dex-spacing-1x));
}

.p-l-8x {
  padding-left: calc(8 * var(--dex-spacing-1x));
}

.p-l-9x {
  padding-left: calc(9 * var(--dex-spacing-1x));
}

.p-l-10x {
  padding-left: calc(10 * var(--dex-spacing-1x));
}

.p-r-025x {
  padding-right: calc(0.25 * var(--dex-spacing-1x));
}

.p-r-05x {
  padding-right: calc(0.5 * var(--dex-spacing-1x));
}

.p-r-075x {
  padding-right: calc(0.75 * var(--dex-spacing-1x));
}

.p-r-1x {
  padding-right: calc(1 * var(--dex-spacing-1x));
}

.p-r-15x {
  padding-right: calc(1.5 * var(--dex-spacing-1x));
}

.p-r-2x {
  padding-right: calc(2 * var(--dex-spacing-1x));
}

.p-r-3x {
  padding-right: calc(3 * var(--dex-spacing-1x));
}

.p-r-4x {
  padding-right: calc(4 * var(--dex-spacing-1x));
}

.p-r-5x {
  padding-right: calc(5 * var(--dex-spacing-1x));
}

.p-r-6x {
  padding-right: calc(6 * var(--dex-spacing-1x));
}

.p-r-7x {
  padding-right: calc(7 * var(--dex-spacing-1x));
}

.p-r-8x {
  padding-right: calc(8 * var(--dex-spacing-1x));
}

.p-r-9x {
  padding-right: calc(9 * var(--dex-spacing-1x));
}

.p-r-10x {
  padding-right: calc(10 * var(--dex-spacing-1x));
}

.b-l-std {
  border: var(--dex-border-line);
}

.b-l-l-std {
  border-left: var(--dex-border-line);
}

.b-r-l-std {
  border-right: var(--dex-border-line);
}

.b-t-l-std {
  border-top: var(--dex-border-line);
}

.b-b-l-std {
  border-bottom: var(--dex-border-line);
}

.b-l-10 {
  border: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-10-color);
}

.b-l-l-10 {
  border-left: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-10-color);
}

.b-r-l-10 {
  border-right: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-10-color);
}

.b-t-l-10 {
  border-top: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-10-color);
}

.b-b-l-10 {
  border-bottom: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-10-color);
}

.b-l-20 {
  border: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-20-color);
}

.b-l-l-20 {
  border-left: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-20-color);
}

.b-r-l-20 {
  border-right: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-20-color);
}

.b-t-l-20 {
  border-top: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-20-color);
}

.b-b-l-20 {
  border-bottom: var(--dex-border-width) var(--dex-border-style)
    var(--core-divider-20-color);
}

.b-r-0 {
  border-radius: 0;
}

.b-tl-r-0 {
  border-top-left-radius: 0;
}

.b-tr-r-0 {
  border-top-right-radius: 0;
}

.b-bl-r-0 {
  border-bottom-left-radius: 0;
}

.b-br-r-0 {
  border-bottom-right-radius: 0;
}

.b-r-4 {
  border-radius: 4px;
}

.b-tl-r-4 {
  border-top-left-radius: 4px;
}

.b-tr-r-4 {
  border-top-right-radius: 4px;
}

.b-bl-r-4 {
  border-bottom-left-radius: 4px;
}

.b-br-r-4 {
  border-bottom-right-radius: 4px;
}

.b-r-6 {
  border-radius: 6px;
}

.b-tl-r-6 {
  border-top-left-radius: 6px;
}

.b-tr-r-6 {
  border-top-right-radius: 6px;
}

.b-bl-r-6 {
  border-bottom-left-radius: 6px;
}

.b-br-r-6 {
  border-bottom-right-radius: 6px;
}

.b-r-8 {
  border-radius: 8px;
}

.b-tl-r-8 {
  border-top-left-radius: 8px;
}

.b-tr-r-8 {
  border-top-right-radius: 8px;
}

.b-bl-r-8 {
  border-bottom-left-radius: 8px;
}

.b-br-r-8 {
  border-bottom-right-radius: 8px;
}

.b-r-12 {
  border-radius: 12px;
}

.b-tl-r-12 {
  border-top-left-radius: 12px;
}

.b-tr-r-12 {
  border-top-right-radius: 12px;
}

.b-bl-r-12 {
  border-bottom-left-radius: 12px;
}

.b-br-r-12 {
  border-bottom-right-radius: 12px;
}

.sh-10 {
  box-shadow: var(--core-elevation-10-color);
}

.sh-20 {
  box-shadow: var(--core-elevation-20-color);
}

.sh-30 {
  box-shadow: var(--core-elevation-30-color);
}
